import Vue from 'vue'
import objectFitImages from 'object-fit-images'
import App from './App.vue'
import router from './router'


Vue.config.productionTip = false
objectFitImages()

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')


// new Vue({
//   el: '#app',
//   router,
//   components: { App
//   },
//   template: '<App/>'
// })