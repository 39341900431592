<template>
  <div class="header">
    <div class="container">
      <div class="header-left">
        <a href="#home"><img class="logo" src="../assets/logo.png"></a>
      </div>
      <div class="header-right">
            <router-link to="/home" exact>HOME</router-link>
            <router-link to="/news" exact>NEWS</router-link>
            <router-link to="/company" exact>COMPANY</router-link>
            <router-link to="/business" exact>WORKS</router-link>
            <router-link to="/career" exact>CAREER</router-link>
            <router-link to="/contact" exact>CONTACT</router-link>
      </div>

      <!--ハンバーガーメニューのボタン-->
      <div class="hamburger-menu">
        <div class="hamburger_btn" v-on:click='ActiveBtn=!ActiveBtn'>
          <span class="line line_01" v-bind:class="{'btn_line01':ActiveBtn}"></span>
          <span class="line line_02" v-bind:class="{'btn_line02':ActiveBtn}"></span>
          <span class="line line_03" v-bind:class="{'btn_line03':ActiveBtn}"></span>
        </div>
      </div>
    <!--サイドバー-->
      <transition name="menu">
        <div class="menu" v-show="ActiveBtn">
            <ul>
              <router-link to="/home" exact>HOME</router-link>
              <router-link to="/news" exact>NEWS</router-link>
              <router-link to="/company" exact>COMPANY</router-link>
              <router-link to="/business" exact>WORKS</router-link>
              <router-link to="/career" exact>CAREER</router-link>
              <router-link to="/contact" exact>CONTACT</router-link>
            </ul>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  props: ['ActiveBtn'],
  methods: {
    increment() {
      this.ActiveBtn = false
    },
   },
 }
</script>



