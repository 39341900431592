<template>
  <div id="app">
    <tab-menu></tab-menu>
    <router-view/>
      <div class="footer">
          <div class="container">
            <p>＞個人情報保護方針</p>
            <p>COPYRIGHT ©NEWSUN CO., LTD. ALL RIGHTS RESERVED.</p>
          </div>
      </div>
  </div>
</template>

<script>
import TabMenu from '@/components/TabMenu.vue'

export default {
  name: 'App',
  components: {
    TabMenu
  }
}
</script>

<style scoped>
  @import './assets/newsun.css';
  @import './assets/media.css';
</style>




